import axios from 'axios'
import { getAuth } from 'firebase/auth'

let cancelRequest;

// const searchURL = 'http://localhost:4000'
// const searchURL = "http://137.184.189.32:4000";
const searchURL = 'https://searchapi-test.nanocrowd.io'

const searchApiClient = axios.create({
  baseURL: `${searchURL}`,
  // timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const baseURL = 'https://nc-data-api-test.nanocrowd.io'
// const baseURL = 'http://localhost:2358'

const apiClient = axios.create({
  baseURL: `${baseURL}`,
  // timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  async search(queryString, ignoreList) {
    // avoid sending queries that are the selected item in the dropdown
    if (queryString.trim().length === 0 || queryString.trim().length > 0 && queryString.includes(' • ')) {
      return
    }
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      if (cancelRequest) {
        cancelRequest()
      }

      try {
        return await searchApiClient({
          method: 'POST',
          url: `/api/heatmap`,
          headers: {
            AuthToken: idToken,
          },
          data: {
            queryString,
            ignoreList,
          },
          cancelToken: new axios.CancelToken((c) => {
            cancelRequest = c
          }),
        })  
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.error('Failed to fetch suggestions:', error);
        }        
      }
    }
    return {}
  },
  async getTitleList() {
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      return await searchApiClient({
        method: 'GET',
        url: `/api/titles`,
        headers: {
          AuthToken: idToken,
        }
      })
    }
    return {}
  },
  async getTitle(ncTitleId) {
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      return await apiClient({
        method: 'GET',
        url: `/api/titles/details/${ncTitleId}`,
        headers: {
          AuthToken: idToken,
        }
      })
    }
    return {}
  },
  async fetchTitleHeatmapData(titleIdList) {
    // console.log(`api/heatmaps/titles`)
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      const response = await apiClient({
        method: 'POST',
        url: `api/heatmaps/titles`,
        headers: {
          AuthToken: idToken,
        },
        data: {
          titleIdList
        }
      })

      return response
    }
    return {}
  },
  async getTitlePresets() {
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      return await apiClient({
        method: 'GET',
        url: `/api/heatmaps/presets`,
        headers: {
          AuthToken: idToken,
        }
      })
    }
    return {}
  },
  async fetchTitleNanogenreList() {
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      return await apiClient({
        method: 'GET',
        url: `/api/titles/nanogenres`,
        headers: {
          AuthToken: idToken,
        }
      })
    }
    return {}
  },
  async fetchNanogenreTitleList() {
    const auth = getAuth()
    if (auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true)

      return await apiClient({
        method: 'GET',
        url: `/api/nanogenres/titles`,
        headers: {
          AuthToken: idToken,
        }
      })
    }
    return {}
  },
  async sendHeatmapCoordinates(data) {
    return await apiClient({
      method: 'POST',
      url: `/api/heatmapcoordinates/update`,
      data  
    })
  }
}
